import axios, { InternalAxiosRequestConfig } from 'axios'
import { ACCESS_TOKEN_KEY, API_URL, ROUTES } from './constants'

export const api = axios.create({
  baseURL: API_URL,
})

api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  if (window.location.pathname !== ROUTES.LOGIN) {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY)
    if (token) config.headers.setAuthorization(`Bearer ${token}`)
  }

  return config
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (
      error?.response?.status === 401 &&
      window.location.pathname !== ROUTES.LOGIN
    ) {
      localStorage.removeItem(ACCESS_TOKEN_KEY)
      window.location.href = ROUTES.LOGIN
    }
    return Promise.reject(error)
  },
)
