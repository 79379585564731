import { Button, Layout, message } from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons'
import { useRef } from 'react'

interface ISelectFileProps {
  onSelect?: (file: File) => void
}

const SelectFile: React.FC<ISelectFileProps> = ({ onSelect }) => {
  const file = useRef<HTMLInputElement>(null)
  const [messageApi, contextHolder] = message.useMessage()

  const browseFiles = () => {
    if (file.current) {
      file.current.click()
    }
  }

  const preventDefaults = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    handleOnSelect(e.dataTransfer.files)
    preventDefaults(e)
  }

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    preventDefaults(e)
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleOnSelect(e.target.files)
  }

  const handleOnSelect = (files: FileList | null) => {
    if (!files) {
      return
    }
    if (files.length < 1 || files.length > 1) {
      messageApi.error('You must select a file!')
      return
    }

    if (files[0].name.split('.').pop()?.toLowerCase() !== 'glb') {
      messageApi.error('The file type must be glb!')
    }

    if (onSelect) onSelect(files[0])
  }

  return (
    <Layout
      className="upload"
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragEnter={preventDefaults}
      onDragLeave={preventDefaults}
    >
      {contextHolder}
      <input ref={file} onChange={onChange} type="file" accept=".glb" />
      <CloudUploadOutlined className="icon" />
      <div className="title">Drag and drop glb file here</div>
      <div className="subtitle">or</div>
      <Button className="button" onClick={browseFiles}>
        Browse Files
      </Button>
    </Layout>
  )
}

export default SelectFile
