import React, { forwardRef } from 'react'
import '@google/model-viewer/dist/model-viewer'

interface IModelViewerProps {
  className?: string
  style?: React.CSSProperties
  id?: string
  src: string
  poster?: string
  autoplay?: boolean
  ar?: boolean
  'ios-src'?: string
  'auto-rotate'?: boolean
  'camera-controls'?: boolean
  'ar-modes'?: string
  'interaction-prompt'?: 'none' | 'auto'
  'environment-image'?: string
  'camera-orbit'?: string
  'camera-target'?: string
  'field-of-view'?: string
  exposure?: number
}

export const ModelViewer = forwardRef<any, IModelViewerProps>(
  ({ className, ...props }, ref) => {
    return (
      //@ts-ignore
      <model-viewer class={className} ref={ref} {...props} />
    )
  },
)
