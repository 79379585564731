import { Button, Form, Input, Space, message } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { loginApi } from 'services'
import { ACCESS_TOKEN_KEY } from 'utils/constants'

export const Login: React.FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()

  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
      navigate('/')
    }
  }, [navigate])

  const onFinish = (values: { username: string; password: string }) => {
    setLoading(true)
    loginApi(values)
      .then(({ data }) => {
        localStorage.setItem(ACCESS_TOKEN_KEY, data.access_token)
        navigate('/', { replace: true })
      })
      .catch(() => messageApi.error('Username or password is incorrect!'))
      .finally(() => setLoading(false))
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      {contextHolder}
      <Space align="center" direction="vertical" size="large">
        <div>Login to N-FUZE AR</div>
        <Form onFinish={onFinish}>
          <Form.Item name="username" rules={[{ required: true }]}>
            <Input placeholder="Username" disabled={loading} />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true }, { min: 8 }]}>
            <Input.Password placeholder="Password" disabled={loading} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Login
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </div>
  )
}
