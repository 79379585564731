import { rafPasses } from "utils/raf-passes"

export const createPoster = async (modelViewer: any) => {
  const poster = { height: 512, mimeType: 'image/webp' }
  const height = poster.height / window.devicePixelRatio
  modelViewer.style.width = `${height}px`
  modelViewer.style.height = `${height}px`
  modelViewer.jumpCameraToGoal()
  await rafPasses()
  await rafPasses()
  const posterBlob = await modelViewer.toBlob({
    idealAspect: true,
    mimeType: poster.mimeType,
    qualityArgument: 0.85,
  })
  modelViewer.jumpCameraToGoal()
  modelViewer.style.width = ''
  modelViewer.style.height = ''

  return posterBlob
}
