import {
  Button,
  Col,
  Dropdown,
  FloatButton,
  Layout,
  MenuProps,
  Modal,
  Row,
  Space,
  Typography,
  message,
} from 'antd'
import { Header } from 'antd/es/layout/layout'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IAr, deleteArApi, getAllArApi } from 'services/ar'
import {
  ACCESS_TOKEN_KEY,
  EDITOR_ID_URL,
  ROUTES,
  STATIC_FILE_URL,
  VIEWER_ID_URL,
} from 'utils/constants'

import {
  EditOutlined,
  ShareAltOutlined,
  DeleteFilled,
  UserOutlined,
  PlusOutlined,
  CopyOutlined,
} from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import { embed } from 'utils/embed'
import { ChangePassword } from 'pages/user'

const Home: React.FC = () => {
  const [data, setData] = useState<IAr[]>([])
  const colSpan = {
    xs: { span: 12 },
    sm: { span: 12 },
    md: { span: 8 },
    lg: { span: 6 },
    xl: { span: 4 },
    xxl: { span: 3 },
  }
  const [messageApi, contextHolder] = message.useMessage()
  const navigate = useNavigate()
  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          onClick={() => {
            setChangePassword(true)
          }}
        >
          Change Password
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div
          onClick={() => {
            localStorage.removeItem(ACCESS_TOKEN_KEY)
            navigate(ROUTES.LOGIN)
          }}
        >
          Logout
        </div>
      ),
    },
  ]
  const [share, setShare] = useState<{
    ar: IAr
    url: string
    embedCode: string
  }>()
  const [changePassword, setChangePassword] = useState(false)

  useEffect(() => {
    getAllArApi()
      .then(({ data }) => setData(data))
      .catch((err) => {
        messageApi.error(err?.response?.data?.message)
      })
  }, [messageApi])

  const handleAction = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    type: 'share' | 'edit' | 'delete',
    id: string,
  ) => {
    e.stopPropagation()
    switch (type) {
      case 'share':
        const ar = data.find((v) => v._id === id)
        if (ar) {
          const url = (() => {
            const url = new URL(window.location.href)
            url.pathname = VIEWER_ID_URL(id)
            return url.toString()
          })()
          const embedCode = embed(url)
          setShare({ ar, url, embedCode })
        }
        break
      case 'edit':
        navigate(EDITOR_ID_URL(id))
        break
      case 'delete':
        if (window.confirm('Are you sure?')) {
          deleteArApi(id)
            .then(({ data }) => {
              setData((pre) => [...pre.filter((v) => v._id !== id)])
              messageApi.success(data.message)
            })
            .catch((err) => {
              messageApi.error(err?.response?.data?.message)
            })
        }
        break
    }
  }

  const copy = async (text: string) => {
    await navigator.clipboard.writeText(text)
    message.success('Copied successfully')
  }

  return (
    <Layout style={{ background: 'white' }}>
      <ChangePassword
        messageApi={messageApi}
        show={changePassword}
        setShow={setChangePassword}
      />
      <Modal
        title={`Share "${share?.ar.name}"`}
        open={!!share}
        onOk={() => setShare(undefined)}
        onCancel={() => setShare(undefined)}
      >
        <Typography.Title style={{ marginTop: '1.2rem' }} level={5}>
          URL:
        </Typography.Title>
        <Space.Compact
          style={{
            width: '100%',
            position: 'relative',
          }}
        >
          <TextArea
            style={{
              height: 60,
              resize: 'none',
              width: '100%',
            }}
            value={share?.url}
            readOnly
          />
          <Button
            onClick={() => share?.url && copy(share.url)}
            style={{
              borderRadius: '7px',
              position: 'absolute',
              right: 4,
              bottom: 4,
              width: '3rem',
            }}
          >
            <CopyOutlined />
          </Button>
        </Space.Compact>

        <Typography.Title style={{ marginTop: '1.2rem' }} level={5}>
          Embed code:
        </Typography.Title>
        <Space.Compact
          style={{ width: '100%', marginBottom: '2rem', position: 'relative' }}
        >
          <TextArea
            style={{
              height: 120,
              resize: 'none',
              width: '100%',
            }}
            value={share?.embedCode}
            readOnly
          />
          <Button
            onClick={() => share?.embedCode && copy(share.embedCode)}
            style={{
              borderRadius: '7px',
              position: 'absolute',
              right: 4,
              bottom: 4,
              width: '3rem',
            }}
          >
            <CopyOutlined />
          </Button>
        </Space.Compact>
      </Modal>
      <FloatButton
        icon={<PlusOutlined />}
        onClick={() => navigate(ROUTES.EDITOR)}
      />
      {contextHolder}
      <Header
        className="header"
        style={{
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '1.1rem',
          borderBottom: '1px solid #d6d6d6a9',
        }}
      >
        <div>N-FUZE AR</div>
        <div style={{ flex: 1 }} />
        <Dropdown menu={{ items: menuItems }} placement="bottomLeft">
          <Button size="large" shape="circle" icon={<UserOutlined />} />
        </Dropdown>
      </Header>
      <Row justify={'center'} style={{ padding: '.9rem' }}>
        {data.map((v, i) => (
          <Col
            key={i}
            {...colSpan}
            style={{ padding: '.2rem', maxWidth: '253px' }}
          >
            <div
              style={{
                height: '300px',
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid #d6d6d6a9',
                boxShadow:
                  'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
                overflow: 'hidden',
                borderRadius: '7px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  padding: '.4rem',
                  fontSize: '16px',
                  color: 'rgb(79 79 79)',
                  borderBottom: '1px solid #d6d6d6a9',
                }}
              >
                {v.name}
              </div>
              <div
                onClick={() => navigate(VIEWER_ID_URL(v._id))}
                style={{
                  backgroundImage: `url(${STATIC_FILE_URL}${
                    v.poster
                  }?v=${v.updatedAt?.toString()})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundColor: 'white',
                  cursor: 'pointer',
                  position: 'relative',
                  flex: 1,
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '0.4rem',
                    left: '0.4rem',
                    height: '1.4rem',
                    width: '1.4rem',
                    backgroundColor: v.backgroundColor || 'white',
                    borderRadius: '50%',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'
                  }}
                />
              </div>
              <div
                style={{
                  background: 'white',
                  borderTop: '1px solid #d6d6d6a9',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  width: '100%',
                  padding: '.3rem',
                }}
              >
                <Button
                  onClick={(e) => handleAction(e, 'edit', v._id)}
                  type="dashed"
                  shape="circle"
                  icon={<EditOutlined />}
                />
                <Button
                  onClick={(e) => handleAction(e, 'share', v._id)}
                  shape="circle"
                  icon={<ShareAltOutlined />}
                />
                <Button
                  onClick={(e) => handleAction(e, 'delete', v._id)}
                  danger
                  shape="circle"
                  icon={<DeleteFilled />}
                />
              </div>
            </div>
          </Col>
        ))}

        {/* fill empty spaces */}
        {Array(11)
          .fill(0)
          .map((_v, i) => (
            <Col key={i} {...colSpan} style={{ maxWidth: '253px' }} />
          ))}
      </Row>
    </Layout>
  )
}

export default Home
