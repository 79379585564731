export interface IAr {
  _id: string
  name: string
  environmentImage?: IEnvironmentImage
  cameraOrbit: string
  cameraTarget: string
  fieldOfView: string
  src: string
  usdz: string
  poster: string
  splashScreenPortrait?: string
  splashScreenLandscape?: string
  splashScreenDuration?: number
  backgroundColor?: string
  backgroundImage?: string
  updatedAt?: Date
  exposure?: number
  splashScreenType?: SplashScreenType
}

export interface IEnvironmentImage {
  _id: string
  name: string
  src: string
}

export interface ICreateArBody extends Record<string, any> {
  name: string
  cameraOrbit: string
  cameraTarget: string
  fieldOfView: string
  src: File
  poster: File
  environmentImage?: string
  splashScreenType?: SplashScreenType
}

export interface IUpdateArBody extends Record<string, any> {
  name: string
  cameraOrbit: string
  cameraTarget: string
  fieldOfView: string
  src: File
  poster: File
  environmentImage?: string
  splashScreenPortrait?: File
  splashScreenLandscape?: File
  splashScreenDuration?: number
  backgroundImage?: File
  exposure?: number
  splashScreenType?: SplashScreenType
}

export enum SplashScreenType {
  'IMAGE' = 'IMAGE',
  'VIDEO' = 'VIDEO',
}

export interface ICraeteEnvironmentImageBody
  extends Record<string, string | File> {
  name: string
  src: File
}

export type RemoveFieldType =
  | 'backgroundImage'
  | 'splashScreenPortrait'
  | 'splashScreenLandscape'
