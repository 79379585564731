import { api } from 'utils/api'
import {
  IAr,
  ICraeteEnvironmentImageBody,
  ICreateArBody,
  IEnvironmentImage,
  IUpdateArBody,
  RemoveFieldType,
} from './interfaces'

export const createArApi = (body: ICreateArBody) => {
  const data = new FormData()
  for (const key of Object.keys(body)) {
    data.append(key, body[key])
  }
  return api.post<IAr>('ar', data)
}

export const updateArApi = (id: string, body: IUpdateArBody) => {
  const data = new FormData()
  for (const key of Object.keys(body)) {
    data.append(key, body[key])
  }
  return api.patch<IAr>(`ar/${id}`, data)
}

export const getAllArApi = () => api.get<IAr[]>('ar')
export const getByIdArApi = (id: string) => api.get<IAr>(`ar/${id}`)
export const deleteArApi = (id: string) => api.delete(`ar/${id}`)
export const removeFieldApi = (id: string, field: RemoveFieldType) =>
  api.delete<IAr>(`ar/${id}/${field}`)

export const createEnvironmentImageApi = (
  body: ICraeteEnvironmentImageBody,
) => {
  const data = new FormData()
  for (const key of Object.keys(body)) {
    data.append(key, body[key])
  }
  return api.post<IEnvironmentImage>(`environment-image`, data)
}

export const deleteEnvironmentImageApi = (id: string) =>
  api.delete(`environment-image/${id}`)

export const getAllEnvironmentImageApi = () =>
  api.get<IEnvironmentImage[]>('environment-image')
