import { Flex, Layout, Modal, QRCode, Spin, message } from 'antd'
import { ModelViewer } from 'components/ModelViewer'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IAr, SplashScreenType, getByIdArApi } from 'services/ar'
import { STATIC_FILE_URL } from 'utils/constants'
import { ModelViewerElement } from '@google/model-viewer'
// import { ReactComponent as CreatedByLogo } from 'assets/images/created_by_n-fuze.svg'
import { ReactComponent as ARIcon } from 'assets/images/view_in_ar.svg'
import { ReactComponent as ScreenshotIcon } from 'assets/images/screenshot.svg'
import { LoadingOutlined } from '@ant-design/icons'
import axios from 'axios'

const Viewer: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [data, setData] = useState<IAr>()
  const [messageApi, contextHolder] = message.useMessage()
  const modelViewerRef = useRef<ModelViewerElement>(null)
  const [qrCode, setQrCode] = useState<string>()
  const [splashScreen, setsplashScreen] = useState(false)
  const splashScreenRef = useRef<HTMLDivElement>(null)
  const [splashScreenFile, setSplashScreenFile] = useState<string>()

  useEffect(() => {
    if (id) {
      getByIdArApi(id)
        .then(({ data }) => {
          if (data.splashScreenDuration) {
            setsplashScreen(true)
            axios
              .get(
                `${STATIC_FILE_URL}${
                  window.innerHeight > window.innerWidth
                    ? data.splashScreenPortrait
                    : data.splashScreenLandscape
                }`,
                { responseType: 'blob' },
              )
              .then((res) => setSplashScreenFile(URL.createObjectURL(res.data)))
              .catch(() => setSplashScreenFile('err'))
              .finally(() => {
                setTimeout(() => {
                  const interval = setInterval(() => {
                    if (
                      modelViewerRef.current &&
                      modelViewerRef.current.loaded &&
                      splashScreenRef.current
                    ) {
                      const opacity = +getComputedStyle(splashScreenRef.current)
                        .opacity
                      if (opacity > 0) {
                        splashScreenRef.current.style.opacity = `${
                          opacity - 0.05
                        }`
                      } else {
                        setsplashScreen(false)
                        clearInterval(interval)
                      }
                    }
                  }, 100)
                }, (data.splashScreenDuration || 0) * 1000)
              })
          }
          setData(data)
        })
        .catch((err) => {
          messageApi.error(err?.response?.data?.message)
        })
    }
  }, [id, messageApi])

  const onClickAR = () => {
    if (modelViewerRef.current) {
      if (modelViewerRef.current.canActivateAR) {
        modelViewerRef.current.activateAR()
      } else {
        const url = new URL(window.location.href)
        url.hash = 'ar'
        setQrCode(url.toString())
      }
    }
  }

  const onClickScreenshot = async () => {
    if (modelViewerRef.current) {
      const posterBlob = await modelViewerRef.current.toBlob({
        idealAspect: false,
        mimeType: 'image/png',
        qualityArgument: 1,
      })
      var link = document.createElement('a')
      link.href = URL.createObjectURL(posterBlob)
      link.download = `${Date.now()}.png`
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }

  return (
    <>
      {contextHolder}
      {data && (
        <Layout className="viewer">
          {splashScreen && (
            <div
              ref={splashScreenRef}
              className="splash-screen"
              style={
                data.splashScreenType === SplashScreenType.IMAGE &&
                splashScreenFile
                  ? {
                      backgroundImage: `url(${splashScreenFile})`,
                    }
                  : {}
              }
            >
              {data.splashScreenType === SplashScreenType.VIDEO &&
                splashScreenFile && (
                  <video
                    className="splash-screen-video"
                    autoPlay
                    loop
                    playsInline
                    muted
                    controls={false}
                  >
                    <source src={`${splashScreenFile}`} type="video/mp4" />
                  </video>
                )}
              <div className="spin-wrapper">
                <Spin
                  size="large"
                  indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />}
                />
              </div>
            </div>
          )}
          <Modal
            open={!!qrCode}
            onCancel={() => setQrCode(undefined)}
            footer={false}
            title="Scan the qr code with your mobile or tablet."
            centered
          >
            <Flex justify="center">{qrCode && <QRCode value={qrCode} />}</Flex>
          </Modal>
          {/* <div className="created-by">
            <CreatedByLogo />
          </div> */}
          <div className="actions">
            <div className="screenshot-btn fab" onClick={onClickScreenshot}>
              <ScreenshotIcon />
            </div>
            <div className="ar-btn fab" onClick={onClickAR}>
              <ARIcon />
            </div>
          </div>
          {((!!data.splashScreenDuration && splashScreenFile) ||
            !data.splashScreenDuration) && (
            <ModelViewer
              className="model-viewer"
              src={`${STATIC_FILE_URL}${data.src}`}
              ios-src={`${STATIC_FILE_URL}${data.usdz}`}
              poster={`${STATIC_FILE_URL}${
                data.poster
              }?v=${data.updatedAt?.toString()}`}
              environment-image={
                data.environmentImage
                  ? `${STATIC_FILE_URL}${data.environmentImage.src}`
                  : 'neutral'
              }
              exposure={data.exposure}
              style={{
                backgroundColor: data.backgroundColor,
                backgroundImage: `url(${
                  data?.backgroundImage
                    ? STATIC_FILE_URL + data.backgroundImage
                    : ''
                })`,
              }}
              camera-orbit={data.cameraOrbit}
              camera-target={data.cameraTarget}
              field-of-view={data.fieldOfView}
              ar-modes="webxr scene-viewer quick-look"
              camera-controls
              ref={modelViewerRef}
              ar
            />
          )}
        </Layout>
      )}
    </>
  )
}

export default Viewer
