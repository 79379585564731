export const ACCESS_TOKEN_KEY = 'access_token'
export const API_URL = process.env.REACT_APP_API_URL
export const STATIC_FILE_URL = process.env.REACT_APP_STATIC_FILE_URL

export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  EDITOR: '/editor',
  EDITOR_ID: '/editor/:id',
  VIEWER: '/v/:id'
}

export const EDITOR_ID_URL = (id: string) => ROUTES.EDITOR_ID.replace(':id', id)
export const VIEWER_ID_URL = (id: string) => ROUTES.VIEWER.replace(':id', id)
