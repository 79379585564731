import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Home from './home'
import { Login } from './auth/login'
import Editor from './editor'
import Viewer from './viewer'
import { ROUTES } from 'utils/constants'

export const Pages: React.FC = () => {
  const router = createBrowserRouter([
    {
      path: ROUTES.HOME,
      element: <Home />,
    },
    {
      path: ROUTES.LOGIN,
      element: <Login />,
    },
    {
      path: ROUTES.EDITOR,
      element: <Editor />,
    },
    {
      path: ROUTES.EDITOR_ID,
      element: <Editor />,
    },
    {
      path: ROUTES.VIEWER,
      element: <Viewer />,
    }
  ])

  return <RouterProvider router={router} />
}
