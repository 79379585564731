import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  ColorPicker,
  Form,
  Input,
  Layout,
  Select,
  Tooltip,
  message,
} from 'antd'
import { ModelViewer } from 'components/ModelViewer'
import SelectFile from './SelectFile'
import {
  IAr,
  IEnvironmentImage,
  RemoveFieldType,
  SplashScreenType,
  createArApi,
  getAllEnvironmentImageApi,
  getByIdArApi,
  removeFieldApi,
  updateArApi,
} from 'services/ar'
import { createPoster } from './utils'
import { useNavigate, useParams } from 'react-router-dom'
import { STATIC_FILE_URL } from 'utils/constants'
import {
  MenuOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { SelectMedia } from './SelectMedia'

const { Header, Content, Sider } = Layout

const DEFAULT_ENV = { _id: '', src: '', name: 'Default' }

const Editor: React.FC = () => {
  const ref = useRef<any>()
  const [src, setSrc] = useState<string>()
  const [backgroundColor, setBackgroundColor] = useState<string>('#FFFFFF')
  const [environment, setEnvironment] = useState<IEnvironmentImage>()
  const [environments, setEnvironments] = useState<IEnvironmentImage[]>([])
  const [messageApi, contextHolder] = message.useMessage()
  const { id } = useParams<{ id?: string }>()
  const [data, setData] = useState<IAr>()
  const [file, setFile] = useState<File>()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [siderExpanded, setSiderExpanded] = useState(false)
  const [splashScreen, setSplashScreen] = useState<{
    portrait?: File
    landscape?: File
    duration: number
    type: SplashScreenType
  }>({ duration: 0, type: SplashScreenType.IMAGE })
  const [backgroundImage, setBackgroundImage] = useState<File>()
  const [exposure, setExposure] = useState(1)

  useEffect(() => {
    getAllEnvironmentImageApi()
      .then(({ data }) => {
        setEnvironments([
          { ...DEFAULT_ENV },
          ...data.map((v) => ({ ...v, src: `${STATIC_FILE_URL}${v.src}` })),
        ])
      })
      .catch((err) => {
        messageApi.error(err?.response?.data?.message)
      })
  }, [messageApi])

  useEffect(() => {
    if (id) {
      getByIdArApi(id)
        .then(({ data }) => {
          setSrc(`${STATIC_FILE_URL}${data.src}`)
          data.backgroundColor && setBackgroundColor(data.backgroundColor)
          setEnvironment(
            data.environmentImage
              ? {
                  ...data.environmentImage,
                  src: `${STATIC_FILE_URL}${data.environmentImage.src}`,
                }
              : DEFAULT_ENV,
          )
          setSplashScreen((pre) => ({
            ...pre,
            duration: data.splashScreenDuration || 0,
            type: data.splashScreenType || SplashScreenType.IMAGE,
          }))
          setExposure(data.exposure || 1)
          setData(data)
        })
        .catch((err) => {
          messageApi.error(err?.response?.data?.message)
        })
    } else {
      setEnvironment(DEFAULT_ENV)
    }
  }, [id, messageApi])

  const resetCamera = () => {
    if (ref.current) {
      ref.current.cameraOrbit = '0deg 75deg 105%'
      ref.current.cameraTarget = 'auto auto auto'
      ref.current.fieldOfView = 'auto'
    }
  }

  const onSelectFile = (file: File) => {
    setFile(file)
    setSrc(URL.createObjectURL(file))
  }

  const onSave = async (values: { name: string }) => {
    if (ref.current) {
      if (!ref.current.loaded) {
        alert('Please wait until it is fully loaded')
        return
      }
      setLoading(true)
      const poster = await createPoster(ref.current)
      const data: any = {
        cameraOrbit: ref.current.getCameraOrbit(),
        cameraTarget: ref.current.getCameraTarget(),
        fieldOfView: ref.current.getFieldOfView(),
        name: values.name,
        poster: poster,
        environmentImage: environment?._id || '',
        backgroundColor,
        splashScreenDuration: splashScreen.duration,
        splashScreenType: splashScreen.type || SplashScreenType.IMAGE,
        exposure,
      }
      if (splashScreen.portrait) {
        data.splashScreenPortrait = splashScreen.portrait
      }
      if (splashScreen.landscape) {
        data.splashScreenLandscape = splashScreen.landscape
      }
      if (backgroundImage) {
        data.backgroundImage = backgroundImage
      }
      if (id) {
        updateArApi(id, data)
          .then(({ data }) => {
            setData(data)
            navigate('/', { replace: true })
          })
          .catch((err) => {
            messageApi.error(err?.response?.data?.message)
          })
          .finally(() => setLoading(false))
      } else if (file) {
        data.src = file
        data.poster = new File([poster], 'poster.webp')
        if (environment && environment._id === '') {
          delete data.environmentImage
        }
        createArApi(data)
          .then(({ data }) => {
            setData(data)
            navigate('/', { replace: true })
          })
          .catch((err) => {
            messageApi.error(err?.response?.data?.message)
          })
          .finally(() => setLoading(false))
      } else {
        setLoading(false)
      }
    }
  }

  const onRemoveField = (field: RemoveFieldType) => {
    if (id) {
      removeFieldApi(id, field)
        .then(({ data }) => {
          switch (field) {
            case 'backgroundImage':
              setBackgroundImage(undefined)
              break
            case 'splashScreenPortrait':
              setSplashScreen((pre) => {
                delete pre.portrait
                return pre
              })
              break
            case 'splashScreenLandscape':
              setSplashScreen((pre) => {
                delete pre.landscape
                return pre
              })
              break
          }
          setData(data)
        })
        .catch((err) => {
          messageApi.error(err?.response?.data?.message)
        })
    } else {
      switch (field) {
        case 'backgroundImage':
          setBackgroundImage(undefined)
          break
        case 'splashScreenPortrait':
          setSplashScreen((pre) => {
            delete pre.portrait
            return pre
          })
          break
        case 'splashScreenLandscape':
          setSplashScreen((pre) => {
            delete pre.landscape
            return pre
          })
          break
      }
    }
  }

  return (
    <Layout className="editor">
      {contextHolder}
      {!src && !id ? (
        <SelectFile onSelect={onSelectFile} />
      ) : (
        <>
          <Header
            className="header"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Button
              className="toggle-menu"
              onClick={() => setSiderExpanded((pre) => !pre)}
            >
              {siderExpanded ? <CloseOutlined /> : <MenuOutlined />}
            </Button>
            <div className="title">N-Fuze AR Editor</div>
            <div style={{ flex: 1 }} />
            {(!id || (id && data)) && (
              <Form
                onFinish={onSave}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <Form.Item
                  name="name"
                  initialValue={data?.name}
                  rules={[{ min: 1 }, { required: true, message: '' }]}
                  style={{ marginBottom: 0, marginRight: '0.42rem' }}
                >
                  <Input placeholder="Title"></Input>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Save
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Header>
          {(data || !id) && (
            <Layout className="main">
              <Sider
                width={270}
                className={`sider${siderExpanded ? ' expanded' : ''}`}
              >
                <div className="environment-image">
                  <div style={{ marginBottom: '0.3rem' }}>
                    Environment Image
                  </div>
                  <Select
                    style={{ width: '100%' }}
                    value={environment?._id}
                    options={environments.map((v) => ({
                      label: v.name,
                      value: v._id,
                    }))}
                    onChange={(v) =>
                      setEnvironment(environments.find((_v) => _v._id === v))
                    }
                  />
                </div>
                <div className="background-color">
                  <span>Backgorund Color: </span>
                  <ColorPicker
                    onChange={(e) => setBackgroundColor(e.toHexString())}
                    value={backgroundColor}
                  />
                </div>
                <div className="background-color">
                  <span>Backgorund Image: </span>
                  <SelectMedia
                    type="IMAGE"
                    onRemove={() => onRemoveField('backgroundImage')}
                    onChange={(img) => setBackgroundImage(img)}
                    src={
                      (backgroundImage &&
                        URL.createObjectURL(backgroundImage)) ||
                      (data?.backgroundImage &&
                        `${STATIC_FILE_URL}${
                          data?.backgroundImage
                        }?v=${data.updatedAt?.toString()}`)
                    }
                  />
                </div>
                <div className="background-color">
                  <span>Exposure: </span>
                  <Input
                    defaultValue={data?.exposure}
                    onChange={({ target: { value } }) => {
                      setExposure(+value)
                    }}
                  />
                </div>
                <div className="splash-screen">
                  <span>Splash Screen</span>
                  <div className="items">
                    <div className="item">
                      <div className="title">Portrait: </div>
                      <SelectMedia
                        type={splashScreen.type}
                        onRemove={() => onRemoveField('splashScreenPortrait')}
                        onChange={(portrait) =>
                          setSplashScreen((pre) => ({ ...pre, portrait }))
                        }
                        src={
                          (splashScreen.portrait &&
                            URL.createObjectURL(splashScreen.portrait)) ||
                          (data?.splashScreenPortrait &&
                            `${STATIC_FILE_URL}${
                              data?.splashScreenPortrait
                            }?v=${data.updatedAt?.toString()}`)
                        }
                      />
                    </div>
                    <div className="item">
                      <div className="title">Landscape: </div>
                      <SelectMedia
                        type={splashScreen.type}
                        onRemove={() => onRemoveField('splashScreenLandscape')}
                        onChange={(landscape) => {
                          setSplashScreen((pre) => ({ ...pre, landscape }))
                        }}
                        src={
                          (splashScreen.landscape &&
                            URL.createObjectURL(splashScreen.landscape)) ||
                          (data?.splashScreenLandscape &&
                            `${STATIC_FILE_URL}${
                              data?.splashScreenLandscape
                            }?v=${data.updatedAt?.toString()}`)
                        }
                      />
                    </div>
                    <div className="item">
                      <div className="title">
                        Duration (second):{' '}
                        <Tooltip title="Enter zero to disable!">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                      <Input
                        defaultValue={data?.splashScreenDuration}
                        onChange={({ target: { value } }) => {
                          setSplashScreen((pre) => ({
                            ...pre,
                            duration: +value,
                          }))
                        }}
                      />
                    </div>

                    <div className="item">
                      <div className="title">Type:</div>
                      <Select
                        value={splashScreen.type}
                        options={Object.keys(SplashScreenType).map((v) => ({
                          value: v,
                        }))}
                        onChange={(v) => {
                          setSplashScreen((pre) => ({
                            ...pre,
                            type: v,
                          }))
                        }}
                      />
                    </div>
                  </div>
                </div>
                <Button onClick={resetCamera}>Reset Camera</Button>
              </Sider>
              <Content className="content">
                {src && (
                  <ModelViewer
                    ref={ref}
                    className="model-viewer"
                    src={src}
                    camera-controls
                    interaction-prompt="none"
                    autoplay={false}
                    exposure={exposure}
                    style={{
                      backgroundColor,
                      backgroundImage: `url(${
                        (backgroundImage &&
                          URL.createObjectURL(backgroundImage)) ||
                        (data?.backgroundImage
                          ? STATIC_FILE_URL + data.backgroundImage
                          : '')
                      })`,
                    }}
                    environment-image={environment?.src}
                    camera-orbit={data?.cameraOrbit}
                    camera-target={data?.cameraTarget}
                    field-of-view={data?.fieldOfView}
                    poster={
                      data?.poster
                        ? `${STATIC_FILE_URL}${
                            data?.poster
                          }?v=${data.updatedAt?.toString()}`
                        : ''
                    }
                  />
                )}
              </Content>
            </Layout>
          )}
        </>
      )}
    </Layout>
  )
}

export default Editor
