import { Form, Input, Modal, Space } from 'antd'
import { MessageInstance } from 'antd/es/message/interface'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { changePasswordApi } from 'services/user'
import { ACCESS_TOKEN_KEY, ROUTES } from 'utils/constants'

export const ChangePassword: React.FC<{
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  messageApi: MessageInstance
}> = ({ show, setShow, messageApi }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    if (!localStorage.getItem(ACCESS_TOKEN_KEY))
      navigate(ROUTES.LOGIN, { replace: true })
  }, [navigate])

  const onFinish = (values: { oldPassword: string; password: string }) => {
    setLoading(true)
    changePasswordApi({
      oldPassword: values.oldPassword,
      password: values.password,
    })
      .then(({ data }) => {
        messageApi.success(data.message)
        form.resetFields()
        navigate(ROUTES.HOME, { replace: true })
        setShow(false)
      })
      .catch((err) => {
        messageApi.error(err?.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      title={'Change Password'}
      onOk={() => form.submit()}
      open={show}
      onCancel={() => setShow(false)}
      confirmLoading={loading}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          paddingTop: '1.2rem',
        }}
      >
        <Space align="center" direction="vertical" size="large">
          <Form
            onFinish={onFinish}
            layout="vertical"
            style={{ maxWidth: 600 }}
            form={form}
          >
            <Form.Item
              name="oldPassword"
              rules={[{ required: true }, { min: 8 }]}
            >
              <Input.Password placeholder="Old Password" disabled={loading} />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true }, { min: 8 }]}>
              <Input.Password placeholder="Password" disabled={loading} />
            </Form.Item>
            <Form.Item
              name="password2"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error('The password do not match!'),
                    )
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Confirm Password"
                disabled={loading}
              />
            </Form.Item>
          </Form>
        </Space>
      </div>
    </Modal>
  )
}
