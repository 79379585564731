import { Button } from 'antd'
import { useRef } from 'react'
import { VideoCameraOutlined } from '@ant-design/icons'

interface ISelectMediaProps {
  onChange?: (file: File) => void
  src?: string
  onRemove?: () => void
  type: 'IMAGE' | 'VIDEO'
}

export const SelectMedia: React.FC<ISelectMediaProps> = ({
  src,
  onChange,
  onRemove,
  type,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0 && onChange) {
      onChange(e.target.files[0])
    }
  }

  const onClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
      <input
        onChange={onChangeInput}
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
      ></input>

      <Button
        onClick={onClick}
        style={
          src && type === 'IMAGE'
            ? {
                backgroundImage: `url(${src})`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
              }
            : {}
        }
        icon={src && type === 'VIDEO' ? <VideoCameraOutlined /> : <></>}
      />
      {src && (
        <Button type="text" size="small" danger onClick={onRemove}>
          Remove
        </Button>
      )}
    </div>
  )
}
